import { default as _400NiiVDPIy1TMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500TjKfqfYQVOMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contatoXOHs26m5paMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahiaTfv6CeafdvMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digioujhoaohLlGMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontofrio2tDSwuZhTSMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/pontofrio.vue?macro=true";
import { default as empresalfZl970HuIMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexOU0AuBdL27Meta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infoSXJ76jaXITMeta } from "/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahiaTfv6CeafdvMeta || {},
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digioujhoaohLlGMeta || {},
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-pontofrio",
    path: "/credor/pontofrio",
    meta: pontofrio2tDSwuZhTSMeta || {},
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/credor/pontofrio.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexOU0AuBdL27Meta || {},
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src839753701/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]